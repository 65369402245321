import alerts_pages from './alerts_pages.json';
import api_page from './api_page.json';
import dashboard_page from './dashboard_page.json';
import filters from './filters.json';
import firmwares_page from './firmwares_page.json';
import formation_requests_page from './formation_requests_page.json';
import header from './header.json';
import login_page from './login_page.json';
import messages_page from './messages_page.json';
import models from './models.json';
import navigation from './navigation.json';
import notifications from './notifications.json';
import organizations_page from './organizations_page.json';
import partnership_invitations_page from './partnership_invitations_page.json';
import partnerships_page from './partnerships_page.json';
import thiamis_pages from './thiamis_pages.json';
import user_files_page from './user_files_page.json';
import user_invitations_page from './user_invitations_page.json';
import user_profile_page from './user_profile_page.json';
import users_pages from './users_pages.json';
import views from './views.json';
import widgets from './widgets.json';
import certificates from './certificates_pages.json';
import healthway from './healthway.json';
import calibrations from './calibrations_page.json';

const translations = {
  ...navigation,
  ...models,
  ...views,
  ...alerts_pages,
  ...certificates,
  ...api_page,
  ...dashboard_page,
  ...filters,
  ...firmwares_page,
  ...formation_requests_page,
  ...header,
  ...login_page,
  ...messages_page,
  ...notifications,
  ...organizations_page,
  ...partnership_invitations_page,
  ...partnerships_page,
  ...thiamis_pages,
  ...user_files_page,
  ...user_invitations_page,
  ...user_profile_page,
  ...users_pages,
  ...widgets,
  ...healthway,
  ...calibrations
};

export default translations;
